@import url(~ngx-toastr/toastr.css);
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

.toast-container.toast-top-center .toast,
.toast-container.toast-bottom-center .toast {
  width: 300px;
  margin-left: auto;
  margin-right: auto; }

.toast-container.toast-top-full-width .toast,
.toast-container.toast-bottom-full-width .toast {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999; }
  .toast-container .toast {
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: .75rem 1.25rem .75rem 50px;
    width: 300px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
    pointer-events: auto; }
    .toast-container .toast .toast-title {
      font-weight: bold; }
    .toast-container .toast .toast-message {
      word-wrap: break-word; }
    .toast-container .toast .toast-message a:hover {
      text-decoration: underline; }
    .toast-container .toast .toast-close-button {
      position: relative;
      right: -0.3em;
      top: -0.3em;
      float: right;
      font-size: 20px;
      font-weight: bold;
      color: inherit;
      text-shadow: 0 1px 0 #fff;
      opacity: .5; }
    .toast-container .toast .toast-progress {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      background-color: #000000;
      opacity: 0.3; }
    .toast-container .toast .toast-close-button:hover,
    .toast-container .toast .toast-close-button:focus {
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      opacity: .75; }
  .toast-container .toast:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    opacity: 1;
    cursor: pointer; }

.toast-success {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/check.svg */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgba(21, 87, 36, 0.999999)" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>');
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb; }

.toast-error {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/times-circle.svg */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgba(114, 28, 36, 0.999999)" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/></svg>');
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb; }

.toast-info {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgba(12, 84, 96, 0.999999)" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/></svg>');
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb; }

.toast-warning {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="rgba(133, 100, 4, 0.999999)" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/></svg>');
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba; }

@media all and (max-width: 240px) {
  .toast-container .toast.div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .toast.div {
    padding: 8px 8px 8px 50px;
    width: 18em; }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .toast.div {
    padding: 15px 15px 15px 50px;
    width: 25em; } }

#toast-container > div {
  opacity: 1;
  width: 20em !important;
  padding: 15px 15px 15px 50px !important; }
  [dir=rtl] #toast-container > div {
    background-position: center right .9375rem;
    padding: 15px 50px 15px 15px !important; }

.toast-progress {
  position: absolute;
  top: 0;
  bottom: auto;
  height: .1875rem;
  opacity: .15;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=15);
  filter: alpha(opacity=15); }
  [dir=rtl] .toast-progress {
    right: 0;
    left: auto; }

.toast-close-button {
  position: absolute;
  top: 0;
  color: inherit;
  text-shadow: none;
  right: .25rem !important; }
  [dir=rtl] .toast-close-button {
    right: auto !important;
    left: .25rem !important; }

.default-style #toast-container {
  z-index: 999999; }
  .default-style #toast-container > div {
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5) !important; }
  .default-style #toast-container .toast-close-button {
    font-weight: 700; }
  .default-style #toast-container > .toast-success {
    background-color: #28a745;
    color: #fff; }
  .default-style #toast-container > .toast-error {
    background-color: #dc3545;
    color: #fff; }
  .default-style #toast-container > .toast-info {
    background-color: #17a2b8;
    color: #fff; }
  .default-style #toast-container > .toast-warning {
    background-color: #ffc107;
    color: #664d03; }

.material-style #toast-container {
  z-index: 999999; }
  .material-style #toast-container > div {
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5) !important; }
  .material-style #toast-container .toast-close-button {
    font-weight: 700; }
  .material-style #toast-container > .toast-success {
    background-color: #28a745;
    color: #fff; }
  .material-style #toast-container > .toast-error {
    background-color: #dc3545;
    color: #fff; }
  .material-style #toast-container > .toast-info {
    background-color: #17a2b8;
    color: #fff; }
  .material-style #toast-container > .toast-warning {
    background-color: #ffc107;
    color: #664d03; }

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
